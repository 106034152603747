import _ from 'lodash'
import React, { Component, Fragment } from 'react'
import Parallax, { SlideStruct } from '../components/common/parallax/Parallax'
import Header, { LANDING_LINK_KEY, LINK as HeaderLink } from '../components/layout/Header'
import DOTS from 'vanta/dist/vanta.dots.min'
import GLOBE from 'vanta/dist/vanta.globe.min'
import FOG from 'vanta/dist/vanta.fog.min'
import HALO from 'vanta/dist/vanta.halo.min'

import { BiChip, BiChevronLeft } from 'react-icons/bi'
import { TbMathFunction, TbDeviceAnalytics } from 'react-icons/tb'
import { AiFillControl } from 'react-icons/ai'
import { MdOutlineSwapCalls } from 'react-icons/md'
import { RxScissors } from 'react-icons/rx'
import { RiFlowChart } from 'react-icons/ri'
import { ImShield } from 'react-icons/im'
import { VscFoldDown } from 'react-icons/vsc'

import Video from '../videos/compressed-antelope.mp4'
import Footer from '../components/layout/Footer'

export default class HomePage extends Component {

    constructor (props) {
        super(props)
        this.state = {
            activeLinkKey: LANDING_LINK_KEY,
            shouldShowMainContent: false
        }

        this._mounted = false
        this.contentNodePerKey = {}
        this.previewNodePerKey = {}
        this.vantaEffectPerKey = {}
    }

    componentDidMount () {
        this._mounted = true
    }

    componentDidUpdate (prevProps, prevState) {
        const { activeLinkKey: prevActiveLinkKey } = prevState
        const { activeLinkKey } = this.state
        if (!_.isEqual(prevActiveLinkKey, activeLinkKey)) {
            setTimeout(() => {
                if (this.state.activeLinkKey === activeLinkKey && _.has(this.contentNodePerKey, activeLinkKey)) {
                    if (activeLinkKey === HeaderLink.PROP_TRADING.key) {
                        this.vantaEffectPerKey[activeLinkKey] = FOG({
                            el: this.contentNodePerKey[activeLinkKey],
                            mouseControls: true,
                            touchControls: true,
                            gyroControls: false,
                            minHeight: 200.00,
                            minWidth: 200.00,
                            highlightColor: 0x25410b,
                            midtoneColor: 0x1a4d41,
                            lowlightColor: 0xf3e14,
                            baseColor: 0x3a6651
                        })
                    } else if (activeLinkKey === HeaderLink.OTC.key) {
                        this.vantaEffectPerKey[activeLinkKey] = GLOBE({
                            el: this.contentNodePerKey[activeLinkKey],
                            mouseControls: true,
                            touchControls: true,
                            gyroControls: false,
                            minHeight: 200.00,
                            minWidth: 200.00,
                            scale: 1.00,
                            scaleMobile: 1.00,
                            color: 0x335f5b,
                            color2: 0x4a5087,
                            backgroundColor: 0x161e3e
                        })
                    } else if (activeLinkKey === HeaderLink.CAREER.key) {
                        this.vantaEffectPerKey[activeLinkKey] = DOTS({
                            el: this.contentNodePerKey[activeLinkKey],
                            mouseControls: true,
                            touchControls: true,
                            gyroControls: false,
                            minHeight: 200.00,
                            minWidth: 200.00,
                            scale: 1.00,
                            scaleMobile: 1.00,
                            color: 0xd7bd44,
                            color2: 0x443857,
                            backgroundColor: 0x2a1d34,
                            size: 3.40,
                            spacing: 60.00
                        })
                    } else if (activeLinkKey === HeaderLink.CONTACT.key) {
                        this.vantaEffectPerKey[activeLinkKey] = HALO({
                            el: this.contentNodePerKey[activeLinkKey],
                            mouseControls: true,
                            touchControls: true,
                            gyroControls: false,
                            minHeight: 200.00,
                            minWidth: 200.00,
                            baseColor: 0x50000,
                            backgroundColor: 0x360909,
                            amplitudeFactor: 2.70,
                            size: 0.70
                        })
                    }
                }
            }, 0)

            setTimeout(() => {
                if (this._mounted && this.state.activeLinkKey !== prevActiveLinkKey && _.has(this.vantaEffectPerKey, prevActiveLinkKey)) {
                    this.vantaEffectPerKey[prevActiveLinkKey].destroy()
                }
            }, 600)
        }
    }

    componentWillUnmount () {
        this._mounted = false
        _.forEach(this.vantaEffectPerKey, vantaEffect => {
            if (!_.isNil(vantaEffect)) {
                vantaEffect.destroy()
            }
        })
    }

    Content ({ key, previewComponent, mainComponent, bgColor, videoBg, shouldRenderMainComponent=false }) {
        return (
            <div className='home-page--content' key={key}
                ref={node => this.contentNodePerKey[key] = node}
                style={{ background: bgColor || null }}>
                {!_.isNil(videoBg) && <section className={'home-page--video-background'}>
                    <video 
                        autoPlay
                        playsInline
                        muted 
                        loop >
                        <source src={videoBg} type='video/mp4' />
                    </video>
                    <div className='home-page--video-curtain' />
                </section>}
                
                {shouldRenderMainComponent 
                ? <div className='home-page--content--main'>
                    <div className='home-page--content--body'>
                        <button className='home-page--content--back-button' onClick={() => { this.setState({ shouldShowMainContent: false }) }}>
                            <BiChevronLeft />
                            {`Back`}
                        </button>
                        <section>
                            {mainComponent}
                        </section>
                        <button className='home-page--content--back-button' onClick={() => { this.setState({ shouldShowMainContent: false }) }}>
                            <BiChevronLeft />
                            {`Back`}
                        </button>
                    </div>
                </div> 
                : <div className='home-page--content--preview' ref={node => this.previewNodePerKey[key] = node}>
                    <div className='home-page--content--preview--body'>
                        {previewComponent}
                    </div>
                </div>}
            </div>
        )
    }

    LandingPreview () {
        return (
            <Fragment>
                <div className='landing-preview'>
                    <div className='landing-preview--slogan'>{'Connect the dots in crypto market'}</div>
                    <div className='landing-preview--description'>
                        {'We are a leading quantitative digital asset trading house, established with an extensive business network consisting of top-tier institutions around the globe. '}<br /> 
                        {'Equipped with state-of-art trading systems, we trade across all major CeFi/DeFi exchanges. '}
                    </div>
                    <div className='landing-preview--buttons'>
                        <button className='landing-preview--button market-making' onClick={() => { this.setState({ activeLinkKey: HeaderLink.PROP_TRADING.key }) }}>{`Discover our services`}</button>
                        <button className='landing-preview--button career' onClick={() => { this.setState({ activeLinkKey: HeaderLink.CAREER.key }) }}>{`Join us now`}</button>
                    </div>
                </div>
                <button className='landing-preview--scroll-down-button horizontal-centered' onClick={() => {
                    this.setState({
                        activeLinkKey: HeaderLink.PROP_TRADING.key,
                        shouldShowMainContent: false
                    })
                }}><VscFoldDown /></button>
            </Fragment>

        )
    }

    MarketMakingPreview () {
        return (
            <div className='market-making-preview'>
                <div className='market-making-preview--title'>{`Quench the thirst in market liquidity. `}</div>
                <div className='market-making-preview--description'>
                    {`Through our market-making program, project owners, stakeholders, developers and other market participants could realize their financial needs with efficient prices, dynamic depth and the optimized transparency. `}
                </div>
                <button className='market-making-preview--read-more-button' onClick={() => { this.setState({ shouldShowMainContent: true }) }}>{`Explore our edges`}</button>
            </div>
        )
    }

    OtcPreview () {
        return (
            <div className='otc-preview'>
                <div className='otc-preview--title'>
                    {`OTC Crypto Derivatives Trading`}
                </div>
                <div className='otc-preview--description'>
                    {`We accept both small orders and block trades.`}
                </div>
                <button className='otc-preview--read-more-button' onClick={() => { this.setState({ shouldShowMainContent: true }) }}>{`Find out more`}</button>
            </div>
        )
    }

    CareerPreview () {
        return (
            <div className='career-preview'>
                <div className='career-preview--title'>
                    {`Bring together `}
                    <br />
                    {`the brightest minds. `}
                </div>
                <div className='career-preview--description'>
                    {`Your input matters in Antelope Technology. `}
                    <br />
                    {`Together, we seek the key to success in crypto market. `}
                </div>
                <button className='career-preview--read-more-button' onClick={() => { this.setState({ shouldShowMainContent: true }) }}>{`Check out opportunities`}</button>
            </div>
        )
    }

    ContactPreview () {
        return (
            <div className='contact-preview'>
                <div className='contact-preview--title'>{`Communicate to our team.`}</div>
                <div className='contact-preview--description'>
                    {`Meet us, hear us, talk to us`}
                </div>
                <a href='mailto:info@antelopetechnology.com'><button className='contact-preview--read-more-button'>{'info@antelopetechnology.com'}</button></a>
            </div>
        )
    }

    ContentBlock ({ icon, title, description }) {
        return (
            <div className='content-block'>
                <div className='content-block--icon'>{icon}</div>
                <div className='content-block--title'>{title}</div>
                <div className='content-block--description'>{description}</div>
            </div>
        )
    }

    MarketMakingMain () {
        return (
            <div className='market-making-main'>
                <div className='market-making-main--title'>
                    {'Combine the wisdoms across'}<br />
                    {'mathematics, engineering and finance'}
                </div>
                <section>
                    {this.ContentBlock({
                        icon: <BiChip />,
                        title: `Ultra-low latency`,
                        description: `To benefit from first-mover advantage, trading algorighms are driven by cutting edge technology and executed through optimized connectivity to various leading exchanges. `
                    })}
                    {this.ContentBlock({
                        icon: <TbMathFunction />,
                        title: `Advanced quantitative models`,
                        description: `Financial models are rigorously designed, fine-tuned and deployed to exploit market inefficiencies for active returns. `
                    })}
                    {this.ContentBlock({
                        icon: <TbDeviceAnalytics />,
                        title: `Automated monitoring`,
                        description: `24/7 statistical analytics are applied to real-time market data and trading performance based on our unique criteria. `
                    })}
                    {this.ContentBlock({
                        icon: <AiFillControl />,
                        title: `Reliable risk management`,
                        description: `Our comprehensive array of infrastructures enables us to enforce close supervision on margin management, position control, spread deviations across various market conditions.`
                    })}
                </section>
            </div>
        )
    }

    OtcMain () {
        return (
            <div className='otc-main'>
                <div className='otc-main--title'>{'Pick your token and size to trade '}</div>
                <div className='otc-main--description'>{`Our OTC derivatives desk supports a wide variety of crypto derivatives and accept trades of all sizes. `}</div>
                <section>
                    {this.ContentBlock({
                        icon: <MdOutlineSwapCalls />,
                        title: `Competitive pricing`,
                        description: `We strive to provide the best spread on the OTC crypto derivatives market and to minimize the price impact of block trades.`
                    })}
                    {this.ContentBlock({
                        icon: <RxScissors />,
                        title: `Bespoke products`,
                        description: `With our proprietary system and in-depth knowledge of the crypto market, we deliver offer a wide range of crypto derivatives for your hedging needs.`
                    })}
                    {this.ContentBlock({
                        icon: <RiFlowChart />,
                        title: `Algorithmic trading`,
                        description: `Our OTC crypto derivatives desk is powered by a wide range of market-leading algorithmic trading tools and strategies.  `
                    })}
                    {this.ContentBlock({
                        icon: <ImShield />,
                        title: `Integrity and commitment`,
                        description: `We focus on delivering sustainable performance and creating value. We are always committed to the best market practice.`
                    })}

                </section>
            </div>
        )
    }

    CareerMain () {
        return (
            <div className='career-main'>
                <div className='career-main--description'>
                    {`Antelope technology is a leading quantitative cryptocurrency trading firm that aims to achieve exceptional risk-adjusted returns. 
                    With the combination of comprehensive mathematical analysis, extensive financial market knowledge, and cutting-edge technology solutions, our trading model has stood the test of time. 
                    We are the avant-garde within the market space in terms of systematic decision making, algorithmic execution and active risk management. 
                    The team is constructed with experienced talents from top-tier investment banks like Morgan Stanley/Merrill Lynch (Bank of America)/UBS/Macquarie, as well as graduates from solid academic backgrounds from LSE/Oxford/NTU/SMU. `}
                </div>
                <div className='career-main--title'>{'Open Opportunities'}</div>
                <div className='career-main--roles'>
                    <div className='career-main--role'>
                        <div className='career-main--role--title'>{`Quantitative Trading System Engineer`}</div>
                        <div className='career-main--role--responsibility'>
                            <label>{`You will`}</label>
                            <ul>
                                <li>{`Participate in the development of a comprehensive trading system, gaining exposure to the entire development cycle, including infrastructure and strategy development. 
                                Also, involved in the daily development and maintenance of a low-latency, high-frequency trading platform, covering the underlying architecture, market data, trading engine, and transaction order processing.`}</li>
                                <li>{`Establish interfaces to global exchanges for market quotations and trading, utilizing various protocols including HTTP, WebSocket, and FIX`}</li>
                                <li>{`Maintain communication with the team's business needs and collaborate with other IT members to continuously optimize and enhance the current trading system`}</li>
                                <li>{`Provide daily operational support to ensure smooth trading and develop operational tools`}</li>
                            </ul>
                        </div>
                        <div className='career-main--role--capability'>
                            <label>{`You should have`}</label>
                            <ul>
                                <li>{`Bachelor degree or above in computer science or similar major from top universities`}</li>
                                <li>{`High proficiency in C++/C++17 and related tool chains`}</li>
                                <li>{`Familiar with network programming, basic data structures, and algorithms`}</li>
                                <li>{`Possess strong logical thinking skills, with a capacity for learning new concepts quickly. A critical thinker, self-starter, and adaptable to new challenges`}</li>
                                <li>{`Linux environment development experience is preferred`}</li>
                            </ul>
                        </div>
                    </div>
                    <div className='career-main--role'>
                        <div className='career-main--role--title'>{`Data Development Engineer`}</div>
                        <div className='career-main--role--responsibility'>
                            <label>{`You will`}</label>
                            <ul>
                                <li>{`Database construction for quantitative trading system`}</li>
                                <li>{`Maintain access to a wide range of financial data from domestic and foreign exchanges, data providers and the Internet`}</li>
                                <li>{`Responsible for data collection, aggregation, cleaning and analysis`}</li>
                                <li>{`Data quality control (Including correctness, consistency, completeness, effectiveness, timeliness, etc.)`}</li>
                            </ul>
                        </div>
                        <div className='career-main--role--capability'>
                            <label>{`You should have`}</label>
                            <ul>
                                <li>{`Bachelor degree or above in computer science or related major from top universities`}</li>
                                <li>{`Proficient in one or more languages in Python/Perl/Bash. Hands-on experience in data collection, algorithmic application, and analytic output implementation`}</li>
                                <li>{`Familiar with MySQL and NoSQL databases`}</li>
                                <li>{`Familiar with the Linux development environment, master common algorithms and data structures`}</li>
                                <li>{`Experience in well-known Internet/Quant/Investment data development is preferred`}</li>
                            </ul>
                        </div>
                    </div>
                    <div className='career-main--role'>
                        <div className='career-main--role--title'>{`Quant Trader`}</div>
                        <div className='career-main--role--responsibility'>
                            <label>{`You will`}</label>
                            <ul>
                                <li>{`Develop and manage high-performance, robust-to-market-conditions trading strategies`}</li>
                                <li>{`Closely monitor algorithmic execution to ensure instruments traded at their best prices after cost`}</li>
                                <li>{`Handle multi-dimensional data of high frequency with in-depth knowledge of market microstructure behaviors`}</li>
                            </ul>
                        </div>
                        <div className='career-main--role--capability'>
                            <label>{`You should have`}</label>
                            <ul>
                                <li>{`A top degree in quantitative related fields`}</li>
                                <li>{`Exceptional background in mathematics and logical reasoning`}</li>
                                <li>{`A thorough understanding of traditional and emerging financial markets, comprehensive mindsets of risk management`}</li>
                                <li>{`Solid proficiency in data analysis with various statistical tools and programming languages (python preferred). Practical experience in C++ is a plus`}</li>
                                <li>{`Able to multi-task under stress and volatile market environment, communicate with precision, and react proactively`}</li>
                            </ul>
                        </div>
                    </div>
                    <div className='career-main--role'>
                        <div className='career-main--role--title'>{`Sales Manager/ Business Development`}</div>
                        <div className='career-main--role--responsibility'>
                            <label>{`You will`}</label>
                            <ul>
                                <li>{`Regularly attend business functions, such as industry events and conferences; provide feedback/updates on market and upcoming trends`}</li>
                                <li>{`Work directly with traders to strengthen market knowledge and expertise`}</li>
                                <li>{`Draft marketing material`}</li>
                                <li>{`Actively cultivate counterparty relationships to develop new business opportunities`}</li>
                                <li>{`Work with senior management to develop and execute firm strategy`}</li>
                            </ul>
                        </div>
                        <div className='career-main--role--capability'>
                            <label>{`You should have`}</label>
                            <ul>
                                <li>{`Strong knowledge of crypto markets`}</li>
                                <li>{`2+ years of sales/brokerage experience, sales experience in financial institutions preferred`}</li>
                                <li>{`Bilingual in English and Mandarin, native in both written and verbal communication`}</li>
                                <li>{`Strong presentation skills`}</li>
                                <li>{`Proven ability to work under pressure, self-starter in a fast-paced environment`}</li>
                            </ul>
                        </div>
                    </div>
                    <div className='career-main--role'>
                        <div className='career-main--role--title'>{`Operations Associate`}</div>
                        <div className='career-main--role--responsibility'>
                            <label>{`You will`}</label>
                            <ul>
                                <li>{`Transaction processing for hedge fund subscription, redemption and switching`}</li>
                                <li>{`Calculation and reporting of NAV, daily P&L, investor allocations, management and performance fee`}</li>
                                <li>{`Ensure trading activities are compliant with fund prospectus and governing structure. Liaise with lawyers on legal/compliance document for escrow agreement`}</li>
                                <li>{`Actively manage and maintain external promotional documents`}</li>
                                <li>{`Report directly to senior management`}</li>
                            </ul>
                        </div>
                        <div className='career-main--role--capability'>
                            <label>{`You should have`}</label>
                            <ul>
                                <li>{`Prior experiences with financial intuitions specialized in documentation, fund administration, market risk management are preferred`}</li>
                                <li>{`Solid writing skills. Proficiency in MS office tools, such as Word, Excel and PowerPoint etc. SQL would be an advantage`}</li>
                                <li>{`Proactive, team player and able to work under pressure`}</li>
                                <li>{`Bilingual in English and Mandarin, native in both written and verbal communication`}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='career-main--benefit'>
                    <div className='career-main--benefit--title'>{`Benefits You Deserve`}</div>
                    <div className='career-main--benefit--description'>{`Besides the tremendous career growth potential and invaluable learning opportunities, we also offer: `}</div>
                    <ul>
                        <li>{`Competitive compensation (base + bonus)`}</li>
                        <li>{`Positive working enviroment, flat management structure`}</li>
                        <li>{`Multiple incentive trips annually`}</li>
                        <li>{`Regular team building events, ie. Sports and board games`}</li>
                    </ul>
                </div>
                <div className='career-main--remarks'>
                    <div>{`Interested parties please send your resume to: `}<span>{`hr@antelopetechnology.com`}</span></div>
                    <div>{`Please indicate the name and position of the post: name + position`}</div>
                </div>
            </div>
        )
    }

    render () {
        const { activeLinkKey, shouldShowMainContent } = this.state
        return (
            <div className='home-page'>
                <div className='home-page--header'>
                    <Header onClickLink={(newKey) => { 
                        if (!_.isEqual(activeLinkKey, newKey)) {
                            this.setState({ 
                                activeLinkKey: newKey,
                                shouldShowMainContent: false
                            }) 
                        } else {
                            this.setState({ 
                                shouldShowMainContent: false
                            }) 
                        }
                    }} />
                </div>
                <div className='home-page--main'>
                    <Parallax 
                        slides={[
                            SlideStruct({
                                key: LANDING_LINK_KEY,
                                title: 'Landing',
                                component: this.Content({
                                    key: LANDING_LINK_KEY,
                                    previewComponent: this.LandingPreview(),
                                    videoBg: Video,
                                    bgColor: '#6d5741'
                                })
                            }),
                            SlideStruct({
                                key: HeaderLink.PROP_TRADING.key,
                                title: 'Market Making',
                                component: this.Content({
                                    key: HeaderLink.PROP_TRADING.key,
                                    previewComponent: this.MarketMakingMain(), //this.MarketMakingPreview(),
                                    mainComponent: this.MarketMakingMain(),
                                    bgColor: '#3a6651',
                                    shouldRenderMainComponent: activeLinkKey === HeaderLink.PROP_TRADING.key && shouldShowMainContent
                                })
                            }),
                            SlideStruct({
                                key: HeaderLink.OTC.key,
                                title: 'OTC',
                                component: this.Content({
                                    key: HeaderLink.OTC.key,
                                    previewComponent: this.OtcPreview(),
                                    mainComponent: this.OtcMain(),
                                    bgColor: '#161e3e',
                                    shouldRenderMainComponent: activeLinkKey === HeaderLink.OTC.key && shouldShowMainContent
                                })
                            }),
                            SlideStruct({
                                key: HeaderLink.CAREER.key,
                                title: 'Career',
                                component: this.Content({
                                    key: HeaderLink.CAREER.key,
                                    previewComponent: this.CareerPreview(),
                                    mainComponent: this.CareerMain(),
                                    bgColor: '#2a1d34',
                                    shouldRenderMainComponent: activeLinkKey === HeaderLink.CAREER.key && shouldShowMainContent
                                })
                            }),
                            SlideStruct({
                                key: HeaderLink.CONTACT.key,
                                title: 'Contact Us',
                                component: this.Content({
                                    key: HeaderLink.CONTACT.key,
                                    previewComponent: this.ContactPreview(),
                                    bgColor: '#360909'
                                })
                            })
                        ]} 
                        activeSlideKey={activeLinkKey}
                        shouldIgnoreWheel={shouldShowMainContent}
                        currentNode={this.previewNodePerKey[activeLinkKey]}
                        onChangeSlide={(newKey) => { 
                            if (!_.isEqual(activeLinkKey, newKey)) {
                                this.setState({ 
                                    activeLinkKey: newKey,
                                    shouldShowMainContent: false
                                }) 
                            }
                        }} />
                </div>
                <Footer />
            </div>
        )
    }
}